
/* button[data-setter] {
  outline: none;
  background: transparent;
  border: none;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 18px;
  width: 25px;
  height: 30px;
  color: #F7958E;
  cursor: pointer;
}

button[data-setter]:hover { opacity: 0.5; } */

.circle{
  visibility: hidden;
}

.container {
  position: absolute;
  top: 30px;
  width: 80px;
  height: 89px;
  top: 380px;
  left: 155px;
  margin: 0 auto;
}

.setters {
  position: absolute;
  left: 85px;
  top: 75px;
}

.minutes-set {
  float: left;
  margin-right: 28px;
}

.seconds-set { float: right; }


.timer-cicle {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.display-remain-time {
  font-family: 'CiscoSansTT Regular', 'Helvetica Neue', Arial;
  font-size: medium;
  color: #007AA3;
  visibility: hidden;
  position: absolute;
  left: 0.5em;
}

#pause {
  outline: none;
  background: transparent;
  border: none;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  position: relative;
}

.play::before {
  display: block;
  content: "";
  position: absolute;
  top: 8px;
  left: 16px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 22px solid #F7958E;
}

.pause::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 12px;
  width: 15px;
  height: 30px;
  background-color: transparent;
  border-radius: 1px;
  border: 5px solid #F7958E;
  border-top: none;
  border-bottom: none;
}

#pause:hover { opacity: 0.8; }

.e-c-base {
  fill: none;
  stroke: #B6B6B6;
  stroke-width: 2px
}

.e-c-progress {
  fill: none;
  stroke: #007AA3;
  stroke-width: 4px;
  transition: stroke-dashoffset 0.7s;
}

.e-c-pointer {
  fill: #FFF;
  stroke: #F7958E;
  stroke-width: 4px;
}

#e-pointer { transition: transform 0.7s; }
/* h1 { margin-top:150px; text-align:center;}
body { background-color:#f7f7f7;} */
