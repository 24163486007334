#main-layout {
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.warning-img{
    background: transparent url(images/warning_20_r1.svg) 100% no-repeat; 
    width: 20px;
    height: 20px;
}

.language-list-popover .language-list-popover-text {
    height: 360px;
    overflow: auto;
}

.input-error-text{
  font-family: 'CiscoSansTT Regular';
  margin-left: 5px;
  color: #A12512;
  font-size: 14px;
}

.error-text-container{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top:5px;
}

.empty-username-error{
    display: none;
}

.text-invalid-error{
    display: none;
}

.onboarding-form-logo {
    background: transparent url(images/webex-logo-color.svg) 90% no-repeat;
    background-size: 100%;
    width: 85px;
    height: 62px;
}

.onboarding-form-logo-section{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    font-family: 'CiscoSansTT Regular';
    font-weight: normal;
    margin-top: 23px;
    font-size: 28px;
}

.onboarding-content-more-info{
   margin-top: 5px;
   margin-bottom: 10px;
}

.help-signin-questions {
    font-family: 'CiscoSansTT Regular';
    color: #737678;
    font-size: small;
}

.scan-code{
    display: none;
}

.link {
    font-size: small;
}

.link-footer {
    font-family: 'CiscoSansTT Regular';
    color: #b2b2b2;
    font-size: small;
    display: contents;
    cursor: pointer;
}

#help-signin {
    padding-top: 1em;
}

.footer {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    text-align: center;
    bottom: 1em;
    line-height: 14px;
}

.footer__content {
    font-family: 'CiscoSansTT Regular';
    color: #b2b2b2;
    font-size: small;
}

.footer__content a {
    color: #b2b2b2;
}

.footer__content a:hover {
    text-decoration: none;
}

.button-centered {
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, .08);
    background-color: #6EBF49;
    cursor: pointer;
    box-sizing: border-box;
    width: 130px;
    margin-left: 33%;
    margin-top: 12%;
}

canvas {
    width: 350px;
    height: 350px;
}

.qrcode-displayed {
    margin: 0.5em 0;
    transition: transform .2s;
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 2vh;   
    margin-right: 2vh;
}

#container-circle {
    display: none;
    margin-bottom: 40px;
    margin-top: 20px;
}

#onboarding-form-content {
    margin-top: 35px;
}

.onboarding-content-format {
    font-family: 'CiscoSansTT Regular';
    color: #545454;
    font-size: medium;
}


/* Additional CSS */

.language-globe-image {
    background: transparent url(images/language_16.svg) 100% no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
    display: inline-flex;
    opacity: 70%;
}

.onboarding-language-support {
    position: fixed;
    bottom: 4em;
    display: flex;
    align-items: center;
}

#arrow-image {
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 0.3s;
    cursor: pointer;
    width: 12px;
}

.toggle-arrow {
    background: transparent url(images/arrow-down_32.svg) 100% no-repeat;
    background-size: 100%;
    width: 18px;
    height: 2vh;
    display: inline-flex;
}

.flip {
    transform: rotate(-180deg);
}

.selected-language {
    font-family: 'CiscoSansTT Regular';
    font-size: 12px;
    color: #545454;
    padding: 0px 8px 0px 8px;
}

#momentum-popover {
    visibility: hidden;
}

#language-list {
    width: 100%
}

.translate {
    height: 44px;
    display: flex;
    align-items: flex-start;
    padding-left: 16px;
    display: flex;
    border-bottom: 1px solid #DEDEDE;
    align-items: center;
}

.translate:hover {
    background-color: #91EBFF
}

.md-event-overlay__children {
    overflow: auto;
    bottom: 115px !important;
    width: 235px !important;
    margin-left: -33px;
}

.disable-option {
    color: #B2B2B2;
    pointer-events: none;
}

.popover-tip {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: -26px;
    margin-left: 58px;
    border-width: 9px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    z-index: 1;
    display: none;
}

.md-event-overlay__arrow {
    left: 840px !important;
    top: 838px !important;
}

#multi-language-list{
    cursor: pointer;
    margin-bottom: 24px;
    margin-left: -52px;
  }

  /* .input-and-button{
      width: -webkit-fill-available;
  } */

  .input-username-and-button-container{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .input-serial-number-and-button-container{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .input-serial-number-and-button{
    width: -webkit-fill-available;
    margin-bottom: 25px;
}

  #username{
    border-radius: 8px;
    border-color: #545454;
    font-size: 15px;
  }

  .username-input{
    width: 300px;
  }

  .serial-number-input{
    width: 300px;
  }

  #serialNumber{
    border-radius: 8px;
    border-color: #545454;
    font-size: 15px;
  }


  .username-error{
    border-radius: 8px;
    border-color:#A12512 !important;
    font-size: 15px;
    background-color: #ffd4d4;
  }

  .serialNumber-error{
    border-radius: 8px;
    border-color:#A12512 !important;
    font-size: 15px;
    background-color: #ffd4d4;
  }

  .language-list-popover {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#signInBtn{
    margin:25px 0px 20px 0px;
}


/* The actual language-list-popover */

.language-list-popover .language-list-popover-text {
    visibility: hidden;
    width: 260px;
    height: 375px;
    overflow: auto;
    background-color: white;
    color: black;
    text-align: left;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    box-shadow: inset 0 0 0 1px #d2d5d6, 0 4px 12px 2px rgba(0, 0, 0, .16);
}


/* Toggle this class - hide and show the language-list-popover commented bit might need it later*/

.language-list-popover .show {
    visibility: visible;
    /* -webkit-animation: fadeIn 0.3s;
    animation: fadeIn 0.3s; */
}

.webex-logo {
    width: 125px;
    height: 31px;
    background-size: 100%;
    background: transparent url(images/webex-text-logo.svg) 90% no-repeat;
}

.qr-code-expired {
    display: none;
    width: 35vh;
    height: 35vh;
    justify-content: center;
    align-items: center;
    background: transparent url(images/qr-code-fade.svg) 100% no-repeat;
    background-size: 100%;
}

.webex-logo-text{
    float: left;
    margin-top: 2vh;
    margin-left: 2vh;
}
   
.langulage-select{
    display: flex;
    float: right;
    margin-top: 2vh;
    margin-right: 2vh;
}

.header{
    position: fixed;
    width: 100%;
}

#languages{
    width: 15vw;
    height: 32px;
    background: #FFFFFF;
    padding: 5px 12px 5px 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    font-family: 'CiscoSansTT Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
}

.qrCodeContainer
{
    margin-top: 20px;
}

.globe-image{
    margin-right: 10px;
}


/* Add animation (fade in the language-list-popover) */


/* @-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  } */

/* @media Queries */
/* mobile portrait */
@media (min-width: 340px) and (max-width:480px){
    .onboarding-form-logo {
        width: 68px;
        height: 62px;
    }
    .title {
        font-size: x-large;
        margin-top: 10px;
    }

    .username-input {
        width: 275px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .serial-number-input {
        width: 275px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    #onboarding-form-content{
        margin-top:25px;
    }

    .onboarding-content-format {
        font-size: small;
    }
    .onboarding-content-format-click-here-link {
        font-size: small;
    }
    .footer__content {
        font-size: xx-small;
        padding: 0.5em;
    }
    .onboarding-language-support {
        position: fixed;
        bottom: 6em;
        display: flex;
        align-items: center;
    }
    .link {
        font-size: small;
    }
    .link-footer {
        font-family: 'CiscoSansTT Regular';
        color: #b2b2b2;
        font-size: xx-small;
        display: contents;
    }
    canvas {
        width: 250px;
        height: 250px;
    }

}

@media (max-height:800px) {
    .onboarding-content-more-info {
        margin-top: 5px;
        margin-bottom: 0px;
    }
    
    #container-circle {
        display: none;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    
    .qrCodeContainer {
        margin-top: 5px;
    }
}

@media (min-width: 200px) and (max-width:450px) {
    .webex-logo {
        width: 80px;
        height: 26px;
        background: transparent url(images/webex-text-logo.svg) 90% no-repeat;
        background-size: 100%;
    }

    #languages{
        width: 38vw;
        height: 26px;
        background: #FFFFFF;
        padding: 5px 12px 5px 12px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        font-family: 'CiscoSansTT Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6);
    }

    .globe-image{
        margin-right: 6px;
    }
    
}

@media (min-width: 450px) and (max-width:1000px) {
    .webex-logo {
        width: 90px;
        height: 28px;
        background: transparent url(images/webex-text-logo.svg) 90% no-repeat;
        background-size: 100%;
    }

    #languages{
        width: 30vw;
        height: 28px;
        background: #FFFFFF;
        padding: 5px 12px 5px 12px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        font-family: 'CiscoSansTT Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6);
    }

    .globe-image{
        margin-right: 8px;
    }
}

@media (min-width: 340px) and (max-width:750px) and (orientation:landscape){
    .language-list-popover .language-list-popover-text {
        height: 225px;
        overflow: auto;
    }
}

@media (max-width:320px) and (max-height:568px) {

    .onboarding-form-logo {
        width: 68px;
        height: 62px;
    }
    .title {
        font-size: x-large;
        margin-top: 10px;
    }

    .username-input {
        width: 275px;
    }

    .serial-number-input {
        width: 275px; 
    }

    #onboarding-form-content{
        margin-top:12px;
    }

    .onboarding-content-format {
        font-size: small;
    }
    .onboarding-content-format-click-here-link {
        font-size: small;
    }
    .footer__content {
        font-size: xx-small;
        padding: 0.5em;
    }
    .onboarding-language-support {
        position: fixed;
        bottom: 6em;
        display: flex;
        align-items: center;
    }
    .link {
        font-size: small;
    }
    .link-footer {
        font-family: 'CiscoSansTT Regular';
        color: #b2b2b2;
        font-size: x-small;
        display: contents;
    }
    canvas {
        width: 220px;
        height: 220px;
    }

    #remain-time {
        font-size: x-small;
    }
    
}

/* Tablet */
@media(min-width: 768px)and (max-width:1024px){
    .onboarding-form-logo {    
        width: 85px;
        height: 62px;
    }

    .title{
        font-size: xx-large;
    }

    .onboarding-language-support{
        bottom: 4em;
    }

    .username-input {
        width: 275px;
    }

    .serial-number-input {
        width: 275px; 
    }

}

@media(min-width: 1024px) and (max-width:1366px) and (orientation:landscape){
    .onboarding-form-logo {
        width: 85px;
        height: 62px;
    }

    .onboarding-language-support{
        bottom: 4em;
    }

    .username-input {
        width: 275px;
    }

    .serial-number-input {
        width: 275px; 
    }
}


@media (max-height: 540px) and (max-width: 720px) and (orientation:landscape)  {
    .onboarding-form-logo {
        width: 85px;
        height: 62px;
    }
}

@media (max-height: 700px) and (max-width: 2840px) and (orientation:landscape)  {
    canvas {
        width: 250px;
        height: 250px;
    }
}


@media (max-height: 720px) and (max-width: 540px)  {
    .onboarding-form-logo {
        width: 68px;
        height: 62px;
    }

    .title {
        font-size: 22px;
    }
    #onboarding-form-content {
        margin-top: 20px;
    }

    .username-input {
        width: 275px;
    }

    .serial-number-input {
        width: 275px; 
    }

    .onboarding-content-format {
        font-size: small;
    }

    .onboarding-content-format-click-here-link {
        font-size: small;
    }

    #help-signin {
        padding-top: 0%;
        font-size: small;
    }
    .footer__content {
        font-size: small;
        padding: 0.5em;
    }
    canvas {
        width: 300px;
        height: 300px;
    }
    .content {
        position: relative;
        margin-left: 2vh;   
        margin-right: 2vh;
    }
    .mobile-timer {
        width: 30px;
    } 
    #remain-time {
        font-size: x-small;
    }
    .onboarding-language-support {
        position: fixed;
        bottom: 4em;
        display: flex;
        align-items: center;
    }
    .link {
        font-size: xx-small;
    }
    .link-footer {
        font-family: 'CiscoSansTT Regular';
        color: #b2b2b2;
        font-size: xx-small;
        display: contents;
    }
    .language-globe-image {
        height: 5vh;
    }
    .footer {
        width: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 25px 0px 25px;
        text-align: center;
        bottom: 0em;
        line-height: 14px;
    }
    .language-list-popover .language-list-popover-text {
        height: 200px;
        overflow: auto;
    }
}


